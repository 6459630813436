export const selectApp = (state) => state.app

export const selectHero = (state) => state.app.hero

export const selectAppName = (state) => state.app.name

export const selectCurrentSplashAd = (state) => state.app.currentSplashAd

export const selectIsReadyToShowSplashAd = (state) => state.app.isReadyToShowSplashAd

export const selectIsReadyToEnter = (state) => state.app.isReadyToEnter

export const selectIsViewedAdvertisement = (state) => state.app.isViewedAdvertisement

/** @returns {import("../../../types/api").SchemaCmsPublishResults} */
export const selectNavigationContent = (state) => state.app.navigationContent

export const selectTagList = (state) => state.app.tagList

export const selectProducerList = (state) => state.app.producerList

/**
 * @param {*} state
 * @param {*} a
 * @returns {import('../../../types/api').SchemaVideoRegion[]}
 */
export const selectRegionList = (state, a) => state.app.regionList

/**
 * @param {*} state
 * @param {*} a
 * @returns {import('../../../types/api').SchemaTopicShow[]}
 */
export const selectTopicList = (state, a) => state.app.topicList

/**
 * @param {*} state
 * @returns {import('../../../types/api').SchemaDomainShow}
 */
export const selectFullOrigins = (state) => state.app.fullOrigins

export const selectPreviewOrigin = (state) => state.app.previewOrigin

export const selectOfficialOrigin = (state) => state.app.officialOrigin

export const selectOfficialOriginList = (state) => state.app.officialOriginList

export const selectPictureOrigin = (state) => state.app.pictureOrigin

export const selectComicOrigin = (state) => state.app.comicOrigin

export const selectAdInfo = (state, a) => state.app.adInfo

export const selectAnnouncementList = (state, a) => state.app.announcementList

/**
 * @param {*} state
 * @param {*} a
 * @returns {import('api/index').Station}
 */
export const selectStation = (state, a) => state.app.station

export const selectRouteDetectMessage = (state, a) => state.app.routeDetectMessage

/**
 * @param {*} state
 * @param {*} a
 * @returns {boolean}}
 */
export const selectIsShowingLaunchSplash = (state, a) => state.app.isShowingLaunchSplash

export const selectIsShowingMaintenancePage = (state) => state.app.isShowingMaintenancePage

export const selectMaintenanceMessageInfo = (state) => state.app.maintenanceMessageInfo

export const selectIsShowingFullPageError = (state) => state.app.isShowingFullPageError

export const selectFullPageError = (state) => state.app.fullPageError

export const selectIsShowingFullScreenUpdate = (state) => state.app.isShowingFullScreenUpdate

export const selectNovelOrigin = (state) => state.app.novelOrigin
