import { Provider, useSelector } from 'react-redux'
import { Provider as AlertProvider } from 'react-alert'

import 'init.css'
import 'App.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'

import store from 'redux/store'

import useHero from 'hooks/useHero'

import LaunchSplash from 'component/LaunchSplash'
import Announcement from 'component/Announcement'
import LaunchScreenAd from 'component/Advertisement/LaunchScreen'
import Alert, { ALERT_OPTIONS } from 'component/Alert'
import HintModal, { ReactHintModal } from 'component/Modal/HintModal'
import GlobalModal, { ReactGlobalModal } from 'component/Modal/GlobalModal'
import { AppOutlet } from 'app-layered-layout/AppProvider'
import { GamePopupProvider } from 'hooks/useOpenGamePopup'
import UpdateChecker from 'component/UpdateChecker'
import { report, useAnalyticsPV } from 'hooks/useAnalytics'
import MaintenancePage from 'component/MaintenancePage'
import {
  selectIsShowingFullPageError,
  selectIsShowingFullScreenUpdate,
  selectIsShowingLaunchSplash,
  selectIsShowingMaintenancePage,
} from 'redux/selector/app'
import FullPageError from 'component/FullPageError'
import { useEffect, useRef } from 'react'
import { NAV_TYPE_NORMAL } from 'constant/common'
import { useNavigationLocalData } from 'component/Navigation/hooks/useNavigationLocalData'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useLocation } from 'react-router-dom'
import { INDEX } from 'constant/routers'
import FullPageUpdate from 'component/FullPageUpdate'

if (process.env.REACT_APP_SITE_CODE === '') {
  require('./App.shy.css')
}
if (process.env.REACT_APP_SITE_CODE === 'spring') {
  require('./App.spring.css')
}
if (process.env.REACT_APP_SITE_CODE === 'fx') {
  require('./App.fx.css')
}
if (process.env.REACT_APP_SITE_CODE === 'seman') {
  require('./App.seman.css')
}
if (process.env.REACT_APP_SITE_CODE === 'lls') {
  require('./App.lls.css')
}
if (process.env.REACT_APP_SITE_CODE === 'quick') {
  require('./App.quick.css')
}
if (process.env.REACT_APP_SITE_CODE === 'djr88') {
  require('./App.djr88.css')
}
if (process.env.REACT_APP_SITE_CODE === 'lsav') {
  require('./App.lsav.css')
}
if (process.env.REACT_APP_SITE_CODE === 'xlav') {
  require('./App.xlav.css')
}

ReactHintModal.setAppElement('#root')
ReactGlobalModal.setAppElement('#root')

const START_UP_COUNT_KEY = 'a_startup'
const PREV_LAUNCH_DURATION_KEY = 'a_usage_duration'

function App() {
  // eslint-disable-next-line
  const heroInfo = useHero()
  const navigate = useAppNavigate()
  useAnalyticsPV()

  const showLaunchSplash = useSelector(selectIsShowingLaunchSplash)
  const showMaintenance = useSelector(selectIsShowingMaintenancePage)
  const showFullPageError = useSelector(selectIsShowingFullPageError)
  const showFullScreenUpdate = useSelector(selectIsShowingFullScreenUpdate)

  const cmsTabs = useNavigationLocalData()

  const firstCmsType = cmsTabs.navContent[0]?.type
  const firstCmsPath = cmsTabs.navContent[0]?.navLink
  const firstCmsSearch = cmsTabs.navContent[0]?.search

  const currentLocation = useLocation()
  const initialLocation = useRef(currentLocation)

  useEffect(() => {
    if (initialLocation.current.pathname === INDEX && firstCmsType != null && firstCmsType !== NAV_TYPE_NORMAL) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('home page is not a cms tab, performing navigate')
      }
      navigate(
        {
          pathname: firstCmsPath,
          search: firstCmsSearch,
        },
        { replace: true }
      )
    }
  }, [firstCmsPath, firstCmsSearch, firstCmsType, navigate])

  const logged = useRef(false)
  useEffect(() => {
    if (!logged.current) {
      logged.current = true
      const prevCount = JSON.parse(localStorage.getItem(START_UP_COUNT_KEY) ?? '0')
      const newCount = prevCount + 1
      localStorage.setItem(START_UP_COUNT_KEY, JSON.stringify(newCount))
      report({
        SerialNumber: 1,
        Event: 'app_launch',
        Trigger: '啟動應用',
        Parameters: 'app_launch',
        Value: String(newCount),
      })
    }
  }, [])

  const durationLogged = useRef(false)
  useEffect(() => {
    if (!durationLogged.current) {
      durationLogged.current = true
      const res = JSON.parse(localStorage.getItem(PREV_LAUNCH_DURATION_KEY))
      if (res != null) {
        localStorage.removeItem(PREV_LAUNCH_DURATION_KEY)
        report({
          SerialNumber: 2,
          Event: 'duration',
          Trigger: '在線時長',
          Parameters: 'online_duration',
          Value: String(res),
        })
      }
    }

    let visible = true
    let prevSegment = 0
    let startTime = Date.now()

    const id = setInterval(() => {
      if (visible) {
        const newSeg = Date.now() - startTime
        const full = prevSegment + newSeg
        localStorage.setItem(PREV_LAUNCH_DURATION_KEY, JSON.stringify(full))
      }
    }, 1000)

    const onVisibilityChange = () => {
      if (document.hidden) {
        visible = false
        prevSegment += Date.now() - startTime
      } else {
        visible = true
        startTime = Date.now()
      }
    }

    window.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      clearInterval(id)
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  return (
    <Provider store={store}>
      <GamePopupProvider>
        <AlertProvider template={Alert} {...ALERT_OPTIONS}>
          <div id="app">
            {/* App 內各頁面 */}
            <AppOutlet />

            {/* 公告頁面(分為系統公告 和 彈窗廣告公告) */}
            <Announcement />

            {/* App 入站廣告頁面 */}
            <LaunchScreenAd />

            {/* App 入站讀取資料時的顯示畫面 */}
            {showLaunchSplash && <LaunchSplash />}

            {showMaintenance && <MaintenancePage />}

            {showFullPageError && <FullPageError />}

            {showFullScreenUpdate && <FullPageUpdate />}

            {/* App Hint模式 Modal */}
            <HintModal />

            {/* App 全域模式 Modal */}
            <GlobalModal />

            {/* 更新檢查 */}
            <UpdateChecker />
          </div>
        </AlertProvider>
      </GamePopupProvider>
    </Provider>
  )
}

export default App
