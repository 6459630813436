// import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { takeLatest, takeEvery, select, put, call } from 'redux-saga/effects'
import actions from 'redux/action'
import { FETCH_USER_INFO, FETCH_NEW_TOKEN_INFO } from 'redux/constant/user'
import md5 from 'md5'
import { fetchTouristLogin, fetchUserInfo, fetchRefreshToken } from 'api'
import { setAnalyticUser } from 'hooks/useAnalytics'
import { combinedStorage } from 'utils/combinedStorage'
import { TOKEN_KEY } from 'redux/constant/user'
import { generateChecksum, unShuffleHex } from 'utils/id-util/update-util'
import { PRELOAD_ID_HASH_KEY, PRELOAD_ID_KEY } from 'index'

// 避免 ctrl+f
const PERSISTED_ID_KEY = [...'di_resu'].reverse().join('')
const HASH_KEY = [...'h_di_resu'].reverse().join('')
const SALT = [...'5h_avon'].reverse().join('')

const CACHED_SHOW_ID_KEY = [...'di_wohs'].reverse().join('')

export function getCachedShowId() {
  return localStorage.getItem(CACHED_SHOW_ID_KEY)
}

function setCachedShowId(id) {
  return localStorage.setItem(CACHED_SHOW_ID_KEY, id)
}

export function getVisitorId() {
  // 產生隨機的 id 避免衝突
  const randomSource = new Uint8Array(64)
  crypto.getRandomValues(randomSource)
  const bootTime = window.performance.now()
  const now = Date.now()
  const randomSourceF64 = new Float64Array(randomSource.buffer)
  randomSourceF64[4] = bootTime
  randomSourceF64[5] = now
  randomSourceF64[6] = localStorage.length
  const str = [...randomSource].map((i) => (i > 16 ? '' : '0') + i.toString(16)).join('')

  let visitorId = md5(str)

  if (combinedStorage.getItem(PRELOAD_ID_KEY) && combinedStorage.getItem(PRELOAD_ID_HASH_KEY)) {
    const id = combinedStorage.getItem(PRELOAD_ID_KEY)
    const h = combinedStorage.getItem(PRELOAD_ID_HASH_KEY)
    if (/^[0-9a-f]{32}$/.test(id) && /^[0-9a-f]{64}$/.test(h)) {
      const actualId = unShuffleHex(id)
      if (generateChecksum(actualId) === h) {
        visitorId = actualId
      }
    }
  }

  // 保存或讀取 id 以避免
  const oldId = combinedStorage.getItem(PERSISTED_ID_KEY)
  if (oldId && md5(SALT + oldId) === combinedStorage.getItem(HASH_KEY)) {
    visitorId = oldId

    if (process.env.NODE_ENV === 'development') {
      console.log('[saga/user] use deviceId ' + oldId + ' from local storage')
    }
  } else {
    combinedStorage.setItem(PERSISTED_ID_KEY, visitorId)
    combinedStorage.setItem(HASH_KEY, md5(SALT + visitorId))

    if (process.env.NODE_ENV === 'development') {
      console.log('saving id ' + visitorId + ' to local storage')
    }
  }

  return visitorId
}

function getStoredToken() {
  const json = localStorage.getItem(TOKEN_KEY)
  if (!json) return null
  try {
    const tokenInfo = JSON.parse(json)
    // 登入時將舊token過期時間視為實際過期時間的一小時前
    const expireTime = (tokenInfo.expires_at - 60) * 1000
    if (Date.now() < expireTime && tokenInfo.access_token) return tokenInfo
  } catch (ex) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('token is expired or not existed')
    }
    localStorage.removeItem(TOKEN_KEY)
  }
  return null
}

export const DEFAULT_ANALYTIC_USER_ID = '0'

export function* fetchUser({ inviteCode }) {
  const visitorId = getVisitorId()

  setAnalyticUser(getCachedShowId() ?? DEFAULT_ANALYTIC_USER_ID)

  let tokenInfo = getStoredToken()
  // try use old token to login
  if (tokenInfo != null && !sessionStorage.getItem('OVERRIDE_DEVICE_ID')) {
    try {
      yield put(actions.updateTokenInfo(tokenInfo))
      // 獲取用戶資料
      const info = yield call(fetchUserInfo)
      setAnalyticUser(info.id)
      setCachedShowId(info.id)
      yield put(actions.updateUser(info))
      return
    } catch (ex) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('login by stored token fail')
      }
      localStorage.removeItem(TOKEN_KEY)
      tokenInfo = null
    }
  }

  // if previous login fail, than request new token to login
  // 自動使用遊客身份登入
  tokenInfo = yield call(fetchTouristLogin, {
    visitorId: sessionStorage.getItem('OVERRIDE_DEVICE_ID') || visitorId,
    inviteCode,
  })

  yield put(actions.updateTokenInfo(tokenInfo))

  // 獲取用戶資料
  const info = yield call(fetchUserInfo)
  yield put(actions.updateUser(info))
}

function* updateUser({ type, payload }) {
  yield select((state) => state.user)
}

function* fetchNewTokenInfo() {
  try {
    const tokenInfo = yield call(fetchRefreshToken)
    yield put(actions.updateTokenInfo(tokenInfo))
  } catch (e) {
    window.location.reload()
  }
}

function* updateTokenTimer() {
  yield put(actions.updateTokenTimer())
}

export default function* watchUser() {
  yield takeEvery(FETCH_USER_INFO, fetchUser)
  yield takeEvery(actions.updateUser, updateUser)

  yield takeLatest(FETCH_NEW_TOKEN_INFO, fetchNewTokenInfo)
  yield takeLatest(actions.updateTokenInfo, updateTokenTimer)
}
