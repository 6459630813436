import { useCallback, useEffect, useState } from 'react'
import { Item, ItemDesc, ItemTitle, SafeBottomPadding } from './Styled'
import HeaderBox from 'component/PageModalHeaderBox'
import { HeaderWrap, PageScrollWrap, PageWrapperNonScroll } from 'commonStyledComponents'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { checkDebugSecret } from 'page/SystemConfig'
import { useAppNavigate } from 'app-layered-layout/useAppNavigate'
import { useDispatch, useSelector } from 'react-redux'
import { selectInviteCode } from 'redux/selector/user'
import {
  selectComicOrigin,
  selectFullOrigins,
  selectNovelOrigin,
  selectOfficialOrigin,
  selectPictureOrigin,
  selectPreviewOrigin,
} from 'redux/selector/app'
import useSelectModal from 'hooks/useSelectModal'
import actions from 'redux/action'
import { combinedStorage } from 'utils/combinedStorage'

const PERSISTED_ID_KEY = [...'di_resu'].reverse().join('')

const DEBUG_FLAGS = [
  ['顯示所有付款方式', 'PAYMENT'],
  ['強制顯示付款介面', 'FORCE_PAYMENT_UI'],
  ['小說亮度遮罩', 'NOVEL_BRIGHTNESS'],
  ['路由LOG', 'ROUTE_LOG'],
  ['事件LOG', 'EVENT_LOG'],
]

export default function DebugOnly() {
  const { secret } = useParamsOfPage('yOu-CAN_t-s66-rne')
  const navigate = useAppNavigate()

  const inviteCode = useSelector(selectInviteCode)

  const [hasUnsavedChange, setHasUnsavedChange] = useState(false)

  const currentOverrideAPI = sessionStorage.getItem('OVERRIDE_API')
  const [overrideAPI, setOverrideAPI] = useState(currentOverrideAPI ?? '')
  const clickOverrideAPI = () => {
    const newVal = prompt('Override api', overrideAPI || process.env.REACT_APP_BASE_URL)
    if (newVal != null) {
      setOverrideAPI(newVal)
      setHasUnsavedChange(true)
    }
  }
  const currentOverrideStationId = sessionStorage.getItem('OVERRIDE_STATION_ID')
  const [overrideStationId, setOverrideStationId] = useState(currentOverrideStationId ?? '')
  const clickOverrideStationId = () => {
    const newVal = prompt('Override station id', overrideStationId || process.env.REACT_APP_STATION_ID)
    if (newVal != null) {
      setOverrideStationId(newVal)
      setHasUnsavedChange(true)
    }
  }
  const saveAndReload = () => {
    if (overrideAPI) {
      sessionStorage.setItem('OVERRIDE_API', overrideAPI)
    } else {
      sessionStorage.removeItem('OVERRIDE_API')
    }
    if (overrideStationId) {
      sessionStorage.setItem('OVERRIDE_STATION_ID', overrideStationId)
    } else {
      sessionStorage.removeItem('OVERRIDE_STATION_ID')
    }
    if (overrideDeviceId) {
      sessionStorage.setItem('OVERRIDE_DEVICE_ID', overrideDeviceId)
    } else {
      sessionStorage.removeItem('OVERRIDE_DEVICE_ID')
    }

    navigate(-2)
    setTimeout(() => {
      window.location.replace('/')
    }, 200)
  }

  const [currentDeviceId, setCurrentDeviceId] = useState(null)
  useEffect(() => {
    let aborted = false
    async function read() {
      const oldId = combinedStorage.getItem(PERSISTED_ID_KEY)
      if (!aborted) {
        setCurrentDeviceId(oldId)
      }
    }

    read()

    return () => {
      aborted = true
    }
  }, [])

  const currentOverrideDeviceId = sessionStorage.getItem('OVERRIDE_DEVICE_ID')

  const [overrideDeviceId, setOverrideDeviceId] = useState(currentOverrideDeviceId ?? '')

  const clickOverrideDeviceId = () => {
    const newVal = prompt('Override device id', overrideDeviceId || currentDeviceId)
    if (newVal != null) {
      setOverrideDeviceId(newVal)
      setHasUnsavedChange(true)
    }
  }

  const clickGenerateRandomDeviceId = () => {
    const newVal = Array.from({ length: 32 })
      .map((i) => Math.floor(Math.random() * 16).toString(16))
      .join('')
    setOverrideDeviceId(newVal)
    setHasUnsavedChange(true)
  }

  const clickClearHashAndUpdate = async () => {
    const keys = await caches.keys()
    for (const key of keys) {
      await caches.delete(key)
    }
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const reg of registrations) {
        await reg.unregister()
      }
    }
    navigate(-2)
    setTimeout(() => {
      window.location.replace('/')
    }, 200)
  }

  const [currentServiceWorkerHash, setCurrentServiceWorkerHash] = useState(null)

  useEffect(() => {
    if (navigator.serviceWorker?.controller) {
      const sw = navigator.serviceWorker
      const handler = (ev) => {
        if (ev.data && typeof ev.data === 'object' && ev.data.type === 'current-hash') {
          setCurrentServiceWorkerHash(ev.data.data)
        }
      }
      sw.addEventListener('message', handler)
      sw.controller.postMessage('get-hash')
      return () => {
        sw.removeEventListener('message', handler)
      }
    }
  }, [])

  const [workerStatus, setWorkerStatus] = useState('unknown')

  useEffect(() => {
    const sw = navigator.serviceWorker
    const handler = (ev) => {
      if (ev.data && typeof ev.data === 'object' && ev.data.type === 'current-status') {
        setWorkerStatus(ev.data.data)
      }
    }
    sw.addEventListener('message', handler)
    return () => {
      sw.removeEventListener('message', handler)
    }
  }, [])

  const updateStatus = useCallback(async function updateStatus(signal) {
    const registration = await navigator.serviceWorker?.getRegistration()
    const target = registration.installing ?? registration.waiting ?? registration.active
    if (signal.aborted) return
    if (target) {
      target.postMessage('get-status')
    } else {
      setWorkerStatus('not installed')
    }
  }, [])

  const [currentRedirectPageVersion, setCurrentRedirectPageVersion] = useState(null)

  useEffect(() => {
    const sw = navigator.serviceWorker
    const handler = (ev) => {
      if (ev.data && typeof ev.data === 'object' && ev.data.type === 'current-wrapper-version') {
        setCurrentRedirectPageVersion(ev.data.data)
      }
    }
    sw.addEventListener('message', handler)
    sw.controller.postMessage('get-wrapper-version')
    return () => {
      sw.removeEventListener('message', handler)
    }
  }, [])

  const reloadWrapperVersion = () => {
    if (navigator.serviceWorker?.controller) {
      setCurrentRedirectPageVersion('reloading...')
      const sw = navigator.serviceWorker
      sw.controller.postMessage('get-wrapper-version')
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    async function run() {
      while (!signal.aborted) {
        await updateStatus(signal)
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }
    run()
    return () => {
      controller.abort()
    }
  }, [updateStatus])

  const reloadWorkerVersion = () => {
    if (navigator.serviceWorker?.controller) {
      setCurrentServiceWorkerHash('reloading...')
      const sw = navigator.serviceWorker
      sw.controller.postMessage('get-hash')
    }
  }

  const fullOrigins = useSelector(selectFullOrigins)

  const officialOrigin = useSelector(selectOfficialOrigin)
  const officialOriginName = fullOrigins.name_original[fullOrigins.domain_original.indexOf(officialOrigin)] ?? ''
  const previewOrigin = useSelector(selectPreviewOrigin)
  const pictureOrigin = useSelector(selectPictureOrigin)
  const comicOrigin = useSelector(selectComicOrigin)
  const novelOrigin = useSelector(selectNovelOrigin)

  const { openSelect } = useSelectModal()
  const dispatch = useDispatch()

  const onClickOfficialOrigin = () => {
    openSelect({
      title: 'official(影片域名)',
      options: fullOrigins.name_original.map((n, i) => ({
        text: n + ' ' + fullOrigins.domain_original[i],
        onSelect: () => {
          dispatch(actions.updateOfficialOrigin(fullOrigins.domain_original[i]))
        },
      })),
    })
  }
  const onClickPreviewOrigin = () => {
    openSelect({
      title: 'preview(預覽域名)',
      options: fullOrigins.domain_preview.map((u, i) => ({
        text: u,
        onSelect: () => {
          dispatch(actions.updatePreviewOrigin(u))
        },
      })),
    })
  }
  const onClickPictureOrigin = () => {
    openSelect({
      title: 'picture(圖片域名)',
      options: fullOrigins.domain_picture.map((u, i) => ({
        text: u,
        onSelect: () => {
          dispatch(actions.updatePictureOrigin(u))
        },
      })),
    })
  }
  const onClickComicOrigin = () => {
    openSelect({
      title: 'comic(漫畫域名)',
      options: fullOrigins.domain_comic.map((u, i) => ({
        text: u,
        onSelect: () => {
          dispatch(actions.updateComicOrigin(u))
        },
      })),
    })
  }
  const onClickNovelOrigin = () => {
    openSelect({
      title: 'novel(小說域名)',
      options: fullOrigins.domain_novel.map((u, i) => ({
        text: u,
        onSelect: () => {
          dispatch(actions.updateNovelOrigin(u))
        },
      })),
    })
  }

  const [currentFlags, setCurrentFlags] = useState(null)

  useEffect(() => {
    try {
      const str = localStorage.getItem('DEBUG')
      if (currentFlags == null) {
        if (str) {
          setCurrentFlags(JSON.parse(str).slice(1))
        } else {
          setCurrentFlags([])
        }
      }
    } catch (err) {}
  }, [currentFlags])

  if (!checkDebugSecret(secret)) {
    return <></>
  }

  const clearFlags = () => {
    setCurrentFlags([])
    localStorage.removeItem('DEBUG')
  }

  const toggleFlag = (flag) => {
    if (currentFlags == null) return
    let newFlags
    if (currentFlags.includes(flag)) {
      newFlags = currentFlags.filter((i) => i !== flag)
    } else {
      newFlags = [...currentFlags, flag]
    }
    setCurrentFlags(newFlags)
    if (newFlags.length > 0) {
      localStorage.setItem('DEBUG', JSON.stringify([secret, ...newFlags]))
    } else {
      localStorage.removeItem('DEBUG')
    }
  }

  return (
    <PageWrapperNonScroll>
      <HeaderWrap>
        <HeaderBox headerTitle={'DEBUG ONLY'} />
      </HeaderWrap>
      <PageScrollWrap>
        <ItemTitle>
          Version/invite code <br />
          版本與邀請碼
        </ItemTitle>
        <Item className="readonly">
          <div>Invite Code</div>
          <div>{inviteCode}</div>
        </Item>
        <Item className="readonly">
          <div>App Hash</div>
          <div>{process.env.REACT_APP_GIT_SHA}</div>
        </Item>
        <Item onClick={updateStatus} className="readonly">
          <div>Installation Status</div>
          <div>{workerStatus}</div>
        </Item>
        <Item onClick={reloadWorkerVersion}>
          <div>Current Worker version</div>
          <div>{currentServiceWorkerHash}(Click to reload)</div>
        </Item>
        <Item onClick={reloadWrapperVersion}>
          <div>Current Redirect Page version</div>
          <div>{currentRedirectPageVersion}(Click to reload)</div>
        </Item>
        <Item onClick={clickClearHashAndUpdate}>
          <div>Clear cache and update</div>
          <div></div>
        </Item>

        <ItemTitle>
          API / UUID <br />
          API 線路與用戶 id
        </ItemTitle>
        <ItemDesc>* 需要保存並重啟以生效</ItemDesc>
        <Item className="readonly">
          <div>Default API</div>
          <div>{process.env.REACT_APP_BASE_URL}</div>
        </Item>
        <Item onClick={clickOverrideAPI}>
          <div>Override API</div>
          <div>{overrideAPI}</div>
        </Item>
        <Item className="readonly">
          <div>Default station id</div>
          <div>{process.env.REACT_APP_STATION_ID}</div>
        </Item>
        <Item onClick={clickOverrideStationId}>
          <div>Override station id</div>
          <div>{overrideStationId}</div>
        </Item>
        <Item className="readonly">
          <div>Current device id</div>
          <div>{currentDeviceId}</div>
        </Item>
        <Item onClick={clickOverrideDeviceId}>
          <div>Override device id</div>
          <div>{overrideDeviceId}</div>
        </Item>
        <Item onClick={clickGenerateRandomDeviceId}>
          <div>Generate random override device id</div>
        </Item>
        <Item onClick={saveAndReload} className={hasUnsavedChange ? 'unsaved' : ''}>
          <div>Save and reload 保存並重啟</div>
        </Item>

        <ItemTitle>Origins 資源線路</ItemTitle>
        <ItemDesc>* 只在當前瀏覽階段生效</ItemDesc>
        <Item onClick={onClickOfficialOrigin}>
          <div style={{ whiteSpace: 'nowrap' }}>official (影片來源)</div>
          <div>
            {officialOriginName} {officialOrigin}
          </div>
        </Item>
        <Item onClick={onClickPreviewOrigin}>
          <div style={{ whiteSpace: 'nowrap' }}>preview (預覽來源)</div>
          <div>{previewOrigin}</div>
        </Item>
        <Item onClick={onClickPictureOrigin}>
          <div style={{ whiteSpace: 'nowrap' }}>picture (圖片來源)</div>
          <div>{pictureOrigin}</div>
        </Item>
        <Item onClick={onClickComicOrigin}>
          <div style={{ whiteSpace: 'nowrap' }}>comic (漫畫來源)</div>
          <div>{comicOrigin}</div>
        </Item>
        <Item onClick={onClickNovelOrigin}>
          <div style={{ whiteSpace: 'nowrap' }}>novel (小說來源)</div>
          <div>{novelOrigin}</div>
        </Item>

        <ItemTitle>Debug flags 測試選項</ItemTitle>
        <Item onClick={clearFlags}>
          <div>清除所有測試選項</div>
        </Item>
        {DEBUG_FLAGS.map((m) => (
          <Item onClick={() => toggleFlag(m[1])} key={m[1]}>
            <div>{m[0]}</div>
            {currentFlags?.includes(m[1]) ? <div style={{ color: 'red' }}>enabled</div> : <div>disabled</div>}
          </Item>
        ))}
        <ItemTitle>Update Test 測試更新</ItemTitle>
        <Item onClick={() => dispatch(actions.updateShowFullScreenUpdate(true))}>
          <div>顯示強制更新</div>
        </Item>
        <SafeBottomPadding />
      </PageScrollWrap>
    </PageWrapperNonScroll>
  )
}
