import { FullWidthPrimaryButton } from 'commonStyledComponents'
import styled from 'styled-components'

export const MaintenancePageWrapper = styled.div`
  display: flex;
  position: fixed;
  inset: 0 0 0 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: calc(var(--layer-fullpage-modal) + 1);
  color: #fff;
  text-align: center;
  align-items: center;
`

export const UserCardWrapper = styled.div`
  position: relative;
`

export const TutorialWrapper = styled.div`
  position: relative;
  overflow: auto;
  width: calc(100% - 0.82rem);
  height: calc(100% - 0.82rem);
  border-radius: 0.25rem;
  background: white;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
`

export const TutorialImg = styled.img`
  width: 100%;
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 7.89rem;
  height: 9.94rem;
  background-color: white;
  border-radius: 0.25rem;
  overflow: hidden;
`
export const DialogBanner = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/banner/update.png);
  background-repeat: no-repeat;
  padding-bottom: 2.56rem;
  background-size: 100% 100%;
`

// export const PageTitle = styled.div`
//   font-size: 0.4rem;
//   font-weight: 700;
//   padding-bottom: 0.6rem;
// `

export const PageContentLine = styled.div`
  font-size: 0.35rem;
  padding-bottom: 0.5rem;
`

export const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.4rem;
`

export const TextArea = styled.div`
  flex-grow: 1;
  padding: 0.41rem 0.41rem 0;
  color: #5a5a5a;
  font-size: 0.36rem;
  line-height: 0.58rem;
  text-align: left;
`

export const FullWidthPrimaryButtonWithMargin = styled(FullWidthPrimaryButton)`
  margin: 0.41rem;
  width: calc(100% - 0.41rem * 2);
`
