import Splash from 'component/LaunchSplash'
import {
  DialogBanner,
  FullWidthPrimaryButtonWithMargin,
  MaintenancePageWrapper,
  PageContent,
  PageContentLine,
  PageFooter,
  TextArea,
  TutorialImg,
  TutorialWrapper,
  UserCardWrapper,
} from './Styled'
import React, { useMemo, useState } from 'react'
import ConfirmModal, { CONFIRM_MODAL_TYPE } from 'component/Modal/ConfirmModal'
import UserCard from 'page/SystemConfig/component/IDCard/index.js'
import { installFromBucket } from 'utils/id-util/update-util'
import { getVisitorId } from 'redux/saga/user'
import tutorialImg from './update-tutorial.jpeg'

export default function FullPageUpdate() {
  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION + '.' + process.env.REACT_APP_GIT_SHA
  }, [])

  const [page, setPage] = useState('update')

  // const landingUrl = useMemo(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) return 'https://' + process.env.REACT_APP_LANDING_PAGE_HOST
  // }, [])

  // const handleToLanding = useCallback(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) window.open(landingUrl, '_blank')
  // }, [landingUrl])

  const onClickUpdate = () => {
    if (process.env.REACT_APP_BUCKET_SITE_CODE && process.env.REACT_APP_BUCKET_SITE_KEY) {
      installFromBucket(getVisitorId(), process.env.REACT_APP_BUCKET_SITE_CODE, process.env.REACT_APP_BUCKET_SITE_KEY, [
        new URL(window.location.href).host,
      ]).then(() => {
        setPage('update_tutorial')
      })
    }
  }

  return (
    <React.Fragment>
      <Splash></Splash>
      {page === 'update' && (
        <MaintenancePageWrapper>
          <PageContent>
            <DialogBanner />
            <TextArea>
              发现新的版本，为避免连接问题请立即更新
              <br />
              <br />
              变更：改善用户端稳定性
            </TextArea>
            <FullWidthPrimaryButtonWithMargin onClick={() => setPage('confirm')}>
              立即更新
            </FullWidthPrimaryButtonWithMargin>
          </PageContent>
          {/* <PageContent>
          <PageTitle>{title}</PageTitle>
          {lines.map((m) => (
            <PageContentLine>{m}</PageContentLine>
          ))}
        </PageContent>

        <FullWidthPrimaryButton onClick={handleRetry}>重试</FullWidthPrimaryButton> */}
          <PageFooter>
            {/* <PageContentLine>{landingUrl && <span onClick={handleToLanding}>前往官网</span>}</PageContentLine> */}
            <PageContentLine>{version}</PageContentLine>
          </PageFooter>
        </MaintenancePageWrapper>
      )}
      {page === 'confirm' && (
        <MaintenancePageWrapper onClick={(e) => e.target === e.currentTarget && setPage('update')}>
          <ConfirmModal
            title="更新提醒"
            message="身份卡用于方便找回帐号，建议先下载保存身份卡，并请妥善保存避免泄漏。"
            cancelText="查看身份卡"
            confirmText="立即更新"
            type={CONFIRM_MODAL_TYPE.OK_CANCEL_CUSTOM}
            onCancel={() => setPage('user_card')}
            onConfirm={onClickUpdate}
          ></ConfirmModal>
        </MaintenancePageWrapper>
      )}
      {page === 'user_card' && (
        <MaintenancePageWrapper onClick={(e) => e.target === e.currentTarget && setPage('confirm')}>
          <UserCardWrapper>
            <UserCard onClose={() => setPage('confirm')} />
          </UserCardWrapper>
        </MaintenancePageWrapper>
      )}
      {page === 'update_tutorial' && (
        <MaintenancePageWrapper>
          <TutorialWrapper>
            <TutorialImg src={tutorialImg} />
          </TutorialWrapper>
        </MaintenancePageWrapper>
      )}
    </React.Fragment>
  )
}
