import { sha256 } from 'js-sha256'
import { v4 } from 'uuid'
const salt = new TextDecoder().decode(
  new Uint8Array(('6e6f766131363838393939'.match(/(..)/g) ?? []).map((i) => parseInt(i, 16)))
)
const generateChecksum = (str) => {
  const hash = sha256.create()
  hash.update(str)
  hash.update(salt)
  const hashValue = hash.hex()
  return hashValue
}
const mod = (n, d) => {
  return ((n % d) + d) % d
}
const OFFSET = 9487
const MAP = 56173
const INV = 7
const shuffleHex = (str) => {
  const step1 = str.match(/(....)/g).map((c) => parseInt(c, 16))
  const step2 = step1.map((c, index) => mod((c + index + OFFSET) * MAP, 65535))
  const res = step2.map((i) => i.toString(16).padStart(4, '0')).join('')
  return res
}
const unShuffleHex = (str) => {
  const step1 = (str.match(/..../g) ?? []).map((i) => parseInt(i, 16))
  const step2 = step1.map((c, index) => mod(c * INV - OFFSET - index, 65535))
  const res = step2.map((i) => i.toString(16).padStart(4, '0')).join('')
  return res
}
const findAndUpdate = (dict, field) => {
  var _a
  const keys = dict.querySelectorAll(':scope > key')
  let target = [...keys].find((k) => k.textContent === field.name)
  if (field.value === '' && !field.optional) {
    throw new Error('bad value')
  }
  if (target && typeof field.value === 'string' && field.value.trim() === '') {
    const prevNode = target.previousSibling
    const textNode = target.nextSibling
    const valueElement = target.nextElementSibling
    if ((prevNode == null ? void 0 : prevNode.nodeType) === dict.TEXT_NODE) {
      prevNode.remove()
    }
    textNode == null ? void 0 : textNode.remove()
    target.remove()
    valueElement.remove()
    return
  }
  if (target == null && field.value === '') {
    return
  }
  if (target == null) {
    const fragment = dict.ownerDocument.createDocumentFragment()
    fragment.append(dict.ownerDocument.createTextNode('\n	'))
    const newKey = dict.ownerDocument.createElement('key')
    newKey.textContent = field.name
    fragment.appendChild(newKey)
    let newValueElement
    switch (field.type) {
      case 'url':
      case 'string':
      case 'multi-line-string':
      case 'image':
        newValueElement = dict.ownerDocument.createElement('string')
        newValueElement.textContent = field.value
        break
      case 'consent': {
        newValueElement = dict.ownerDocument.createElement('dict')
        const dictChild1 = dict.ownerDocument.createElement('key')
        dictChild1.textContent = 'default'
        const dictChild2 = dict.ownerDocument.createElement('string')
        newValueElement.append(dict.ownerDocument.createTextNode('\n		'))
        newValueElement.appendChild(dictChild1)
        newValueElement.append(dict.ownerDocument.createTextNode('\n		'))
        newValueElement.appendChild(dictChild2)
        newValueElement.append(dict.ownerDocument.createTextNode('\n	'))
        break
      }
      default:
        throw new Error('unexpected missing field')
    }
    fragment.append(dict.ownerDocument.createTextNode('\n	'))
    fragment.appendChild(newValueElement)
    const keys2 = [...dict.querySelectorAll(':scope > key')]
    let insertBefore = null
    for (const k of keys2) {
      if (field.name < k.textContent) {
        insertBefore = k
        break
      }
    }
    console.log(fragment)
    if (
      ((_a = insertBefore == null ? void 0 : insertBefore.previousSibling) == null ? void 0 : _a.nodeType) ===
      document.TEXT_NODE
    ) {
      insertBefore = insertBefore == null ? void 0 : insertBefore.previousSibling
    }
    dict.insertBefore(fragment, insertBefore)
    target = newKey
  }
  const toChange = target.nextElementSibling
  switch (field.type) {
    case 'url':
    case 'string':
    case 'multi-line-string':
    case 'image':
      toChange.textContent = field.value
      break
    case 'consent':
      toChange.querySelector(':scope string').textContent = field.value
      break
    case 'boolean':
      if (field.value) {
        toChange.replaceWith(dict.ownerDocument.createElement('true'))
      } else {
        toChange.replaceWith(dict.ownerDocument.createElement('false'))
      }
      break
    default:
  }
}
const randomizeUuid = (document2) => {
  const payloadUUIDKeys = [...document2.querySelectorAll('key')].filter((i) => i.textContent === 'PayloadUUID')
  console.log(payloadUUIDKeys)
  for (const key of payloadUUIDKeys) {
    const uuidElement = key.nextElementSibling
    const currentUuid = uuidElement.textContent
    const siblingIdentifierKey = [...key.parentElement.querySelectorAll(':scope > key')].find(
      (i) => i.textContent === 'PayloadIdentifier'
    )
    const siblingIdentifierElement = siblingIdentifierKey == null ? void 0 : siblingIdentifierKey.nextElementSibling
    const siblingIdentifier = siblingIdentifierElement.textContent
    console.log(currentUuid, siblingIdentifier)
    const newUUID = v4().toUpperCase()
    uuidElement.textContent = newUUID
    siblingIdentifierElement.textContent = siblingIdentifier.replace(currentUuid, newUUID)
  }
}
const parseXml = async (file) => {
  const data = await file.text()
  const parser = new DOMParser()
  const xmlDocument = parser.parseFromString(data, 'application/xml')
  return { document: xmlDocument, ...loadDocument(xmlDocument) }
}
const scanFields = (dict) => {
  if (dict.tagName !== 'dict') {
    return []
  }
  const list = []
  let currentKey = ''
  let nodeIsKey = true
  for (let i = 0; i < dict.children.length; i++) {
    if (nodeIsKey) {
      currentKey = dict.children[i].textContent ?? ''
    } else {
      list.push({
        name: currentKey,
        data: dict.children[i],
      })
    }
    nodeIsKey = !nodeIsKey
  }
  return list
}
const loadDocument = (document2) => {
  var _a, _b, _c
  const topFields = scanFields(document2.querySelector(':root > dict'))
  const PayloadDisplayName = topFields.find((i) => i.name === 'PayloadDisplayName').data.textContent
  const PayloadIdentifier = topFields.find((i) => i.name === 'PayloadIdentifier').data.textContent
  const ConsentText =
    ((_a = topFields.find((i) => i.name === 'ConsentText')) == null
      ? void 0
      : _a.data.querySelector(':scope string').textContent) ?? ''
  const PayloadOrganization =
    ((_b = topFields.find((i) => i.name === 'PayloadOrganization')) == null ? void 0 : _b.data.textContent) ?? ''
  const PayloadDescription =
    ((_c = topFields.find((i) => i.name === 'PayloadDescription')) == null ? void 0 : _c.data.textContent) ?? ''
  const meta = [
    {
      type: 'string',
      name: 'PayloadDisplayName',
      value: PayloadDisplayName,
    },
    {
      type: 'string',
      name: 'PayloadIdentifier',
      value: PayloadIdentifier,
    },
    {
      type: 'multi-line-string',
      name: 'PayloadDescription',
      value: PayloadDescription,
      optional: true,
    },
    {
      type: 'string',
      name: 'PayloadOrganization',
      value: PayloadOrganization,
      optional: true,
    },
    {
      type: 'consent',
      name: 'ConsentText',
      value: ConsentText,
      optional: true,
    },
  ]
  const PayloadContent = topFields.find((i) => i.name === 'PayloadContent').data
  const clips = [...PayloadContent.children].map((e) => {
    const list = scanFields(e)
    const rawImgText = list.find((i) => i.name === 'Icon').data.textContent
    const imgText = rawImgText.replace(/[\r\n\s]/g, '')
    const src = 'data:image/png;base64,' + imgText
    const uuid = list.find((i) => i.name === 'PayloadUUID').data.textContent.replace(/[\r\n\s]/g, '')
    const editableFields = []
    editableFields.push({
      type: 'image',
      name: 'Icon',
      value: rawImgText,
    })
    const label = list.find((i) => i.name === 'Label').data.textContent.replace(/[\r\n\s]/g, '')
    editableFields.push({
      type: 'string',
      name: 'Label',
      value: label,
    })
    const url = list.find((i) => i.name === 'URL').data.textContent.replace(/[\r\n\s]/g, '')
    editableFields.push({
      type: 'url',
      name: 'URL',
      value: url,
    })
    const removeable = list.find((i) => i.name === 'IsRemovable').data.tagName === 'true'
    editableFields.push({
      type: 'boolean',
      name: 'IsRemovable',
      value: removeable,
    })
    const fullScreen = list.find((i) => i.name === 'FullScreen').data.tagName === 'true'
    editableFields.push({
      type: 'boolean',
      name: 'FullScreen',
      value: fullScreen,
    })
    return {
      id: uuid,
      image: src,
      fields: list,
      originalName: label,
      editableFields,
    }
  })
  return { meta, clips }
}
const generateNewXml = (doc, meta, clips) => {
  const newDoc = doc.cloneNode(true)
  const rootList = newDoc.querySelector(':root > dict')
  console.log(rootList)
  for (const f of meta) {
    findAndUpdate(rootList, f)
  }
  const keys = rootList.querySelectorAll(':scope > key')
  const contentKey = [...keys].find((i) => i.textContent === 'PayloadContent')
  const apps = [...contentKey.nextElementSibling.querySelectorAll(':scope > dict')]
  for (const app of apps) {
    const keys2 = app.querySelectorAll(':scope > key')
    const idKey = [...keys2].find((i) => i.textContent === 'PayloadUUID')
    const id = idKey.nextElementSibling.textContent
    const targetClip = clips.find((i) => i.id === id)
    if (targetClip) {
      for (const f of targetClip.editableFields) {
        findAndUpdate(app, f)
      }
    }
  }
  randomizeUuid(newDoc)
  return newDoc
}
const installNewXml = (doc, meta, clips) => {
  const xml = generateNewXml(doc, meta, clips)
  const serializer = new XMLSerializer()
  const xmlStr = serializer.serializeToString(xml)
  const blob = new Blob([xmlStr], { type: 'application/x-apple-aspen-config' })
  const url = URL.createObjectURL(blob)
  const frame = document.createElement('iframe')
  frame.style.display = 'none'
  frame.src = url
  document.body.appendChild(frame)
}
const docTypeToString = (docType) =>
  '<!DOCTYPE ' +
  docType.name +
  (docType.publicId ? ' PUBLIC "' + docType.publicId + '"' : '') +
  (!docType.publicId && docType.systemId ? ' SYSTEM' : '') +
  (docType.systemId ? ' "' + docType.systemId + '"' : '') +
  '>'
const installMobileConf = async (confUrl, entryUrl, userId) => {
  const file = await fetch(confUrl, { cache: 'no-cache' })
  if (file.ok) {
    const blob = await file.blob()
    const parsedManifest = await parseXml(blob)
    for (const clip of parsedManifest.clips) {
      const serviceClipURL = clip.editableFields.find(
        (i) => i.name === 'URL' && i.type === 'url' && !/^https:?\/\//.test(i.value)
      )
      const mainPageURL = clip.editableFields.find(
        (i) => i.name === 'URL' && i.type === 'url' && /^https:?\/\//.test(i.value)
      )
      if (serviceClipURL) {
        const servicePageText = await fetch(serviceClipURL.value).then((res) => res.text())
        const parsedDocument = new DOMParser().parseFromString(servicePageText, 'text/html')
        const userIdNode = parsedDocument.querySelector('script[type="x-meta"][data-name="user_id"]')
        if (userIdNode) {
          userIdNode.textContent = shuffleHex(userId)
        }
        const userIdHashNode = parsedDocument.querySelector('script[type="x-meta"][data-name="user_id_h"]')
        if (userIdHashNode) {
          userIdHashNode.textContent = generateChecksum(userId)
        }
        const pageCode =
          (parsedDocument.doctype ? docTypeToString(parsedDocument.doctype) + '\r\n' : '') +
          parsedDocument.documentElement.outerHTML
        serviceClipURL.value = 'data:text/html;base64,' + btoa(unescape(encodeURIComponent(pageCode)))
      }
      if (mainPageURL) {
        const parsed = new URL(mainPageURL.value)
        parsed.searchParams.set('id', shuffleHex(userId))
        parsed.searchParams.set('h', generateChecksum(userId))
        if (entryUrl != null) {
          parsed.host = new URL(entryUrl).host
        }
        mainPageURL.value = parsed.toString()
      }
    }
    installNewXml(parsedManifest.document, parsedManifest.meta, parsedManifest.clips)
  }
}
const PART = 'oss-accelerate.aliyuncs.com'
const generateCode = (siteCode, siteKey) => {
  const date = /* @__PURE__ */ new Date().toISOString().slice(0, 10).replace(/-/g, '')
  const input = `${siteCode}${date}${siteKey}`
  const encoded = sha256(input)
  const result = encoded.slice(0, 63)
  return result
}
const getMetadataUrl = (siteCode, siteKey) => {
  const result = generateCode(siteCode, siteKey)
  return `https://${result}.${PART}/${result}`
}
const getMobileConfUrl = (siteCode, siteKey) => {
  const result = generateCode(siteCode, siteKey)
  return `https://${result}.${PART}/mobile.conf`
}
function base64_encode(s) {
  return btoa(unescape(encodeURIComponent(s)))
}
const utf8decoder = new TextDecoder()
const processMetaRes = async (res) => {
  const arrayBuffer = await res.arrayBuffer()
  const uint8 = new Uint8Array(arrayBuffer)
  const k = 168
  for (let i = 0; i < uint8.length; i++) {
    uint8[i] = uint8[i] ^ k
  }
  return utf8decoder.decode(uint8)
}
const installFromBucket = async (oldId, siteCode, siteKey, avoidDomains = []) => {
  let config
  try {
    const url = getMetadataUrl(siteCode, siteKey)
    config = JSON.parse(await processMetaRes(await fetch(url, { cache: 'no-cache' })))
  } catch (err) {
    console.log(err)
    throw new Error('连线异常，请检查网路环境或联系客服')
  }
  const detectResults = config.entries
    ? await Promise.all(
        config.entries.map(async (entry) => {
          const start = Date.now()
          try {
            await fetch(entry, { cache: 'no-cache' })
            return {
              url: entry,
              time: Date.now() - start,
              valid: true,
            }
          } catch (e) {
            return {
              url: entry,
              time: Infinity,
              valid: false,
            }
          }
        })
      )
    : null
  const liveDomains = detectResults == null ? void 0 : detectResults.filter((i) => i.valid)
  const liveDomainExcludesAvoided =
    detectResults == null ? void 0 : detectResults.filter((i) => i.valid && !avoidDomains.includes(new URL(i.url).host))
  const selected =
    (liveDomainExcludesAvoided == null ? void 0 : liveDomainExcludesAvoided[0]) ??
    (liveDomains == null ? void 0 : liveDomains[0])
  if (config.entries != null && selected == null) {
    throw new Error('连线异常，请检查网路环境或联系客服')
  }
  let mobileConfUrl
  {
    const mobileConfText = await processMetaRes(await fetch(getMobileConfUrl(siteCode, siteKey), { cache: 'no-cache' }))
    mobileConfUrl = 'data:application/x-apple-aspen-config;base64,' + base64_encode(mobileConfText)
  }
  return installMobileConf(mobileConfUrl, (selected == null ? void 0 : selected.url) ?? null, oldId)
}
const canInstallNewConfig = async (siteCode, siteKey) => {
  try {
    const url = getMetadataUrl(siteCode, siteKey)
    JSON.parse(await processMetaRes(await fetch(url, { cache: 'no-cache' })))
  } catch (err) {
    return false
  }
  try {
    const res = await fetch(getMobileConfUrl(siteCode, siteKey), {
      cache: 'no-cache',
    })
    if (!res.ok) {
      return false
    }
  } catch (err) {
    return false
  }
  return true
}
export {
  canInstallNewConfig,
  generateChecksum,
  getMetadataUrl,
  installFromBucket,
  processMetaRes,
  shuffleHex,
  unShuffleHex,
}
