import { useCallback, useState } from 'react'
//
import { FullWidthPrimaryButton, TextError } from 'commonStyledComponents'
import { postGamePaySecurity } from 'api/index'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Wrapper, FormRow, Input } from './Styled'
import { report, useReportAppScreen } from 'hooks/useAnalytics'

export default function CreateWithdrawAccount({ onSuccess }) {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, touched, setFieldValue } = useFormik({
    initialValues: {
      legalName: '',
      bankName: '',
      cardCode: '',
      branchName: '',
    },
    // validateOnChange: false,
    validationSchema: Yup.object().shape({
      legalName: Yup.string().required('请输入帐户持有者名称'),
      bankName: Yup.string().required('请输入银行名称'),
      cardCode: Yup.string().required('请输入银行卡号'),
      branchName: Yup.string().required('请输入支行名称'),
    }),
    onSubmit: async () => {
      if (loading) return
      setLoading(true)
      try {
        await submit()
      } finally {
        setLoading(false)
      }
    },
  })

  // 過濾空白字元
  const handleAccountInput = useCallback(
    (e) => {
      const value = e.target.value
      if (/\s+/gi.test(value)) {
        setFieldValue('cardCode', value.replaceAll(/\s/g, ''))
      } else {
        handleChange(e)
      }
    },
    [handleChange, setFieldValue]
  )

  const submit = async () => {
    const payload = {
      account: values.cardCode,
      bankName: values.bankName,
      branchName: values.branchName,
      legalName: values.legalName,
      remittanceType: 1, // bank
    }
    payload.account = payload.account.replaceAll(/\s/g, '')
    try {
      setErrorMessage('')
      await postGamePaySecurity(payload)
      onSuccess()
    } catch (ex) {
      console.warn(ex)
      setErrorMessage(ex.message)
    }
  }

  useReportAppScreen({
    SerialNumber: 1,
    Event: 'app_screen',
    Trigger: '新增取款帳戶頁面',
    Parameters: 'page_title',
    Value: 'add_withdrawal_account_page',
  })

  return (
    <Wrapper>
      <FormRow>
        <span>真实姓名</span>
        <Input
          name="legalName"
          value={values.legalName}
          onClick={() => {
            report({
              SerialNumber: 2,
              Event: 'add_withdrawal_account_click',
              Trigger: '點擊真实姓名',
              Parameters: 'content',
              Value: 'real_name',
            })
          }}
          onInput={handleChange}
          onBlur={handleBlur}
        ></Input>
      </FormRow>
      <TextError>{touched.legalName && errors.legalName}</TextError>
      <FormRow>
        <span>银行名称</span>
        <Input
          name="bankName"
          value={values.bankName}
          onClick={() => {
            report({
              SerialNumber: 3,
              Event: 'add_withdrawal_account_click',
              Trigger: '點擊银行名称',
              Parameters: 'content',
              Value: 'bank_name',
            })
          }}
          onInput={handleChange}
          onBlur={handleBlur}
        ></Input>
      </FormRow>
      <TextError>{touched.bankName && errors.bankName}</TextError>
      <FormRow>
        <span>银行卡号</span>
        <Input
          name="cardCode"
          value={values.cardCode}
          onClick={() => {
            report({
              SerialNumber: 4,
              Event: 'add_withdrawal_account_click',
              Trigger: '點擊银行卡号',
              Parameters: 'content',
              Value: 'bank_card_number',
            })
          }}
          onInput={handleAccountInput}
          onBlur={handleBlur}
        ></Input>
      </FormRow>
      <TextError>{touched.cardCode && errors.cardCode}</TextError>
      <FormRow>
        <span>开户支行</span>
        <Input
          name="branchName"
          value={values.branchName}
          onClick={() => {
            report({
              SerialNumber: 5,
              Event: 'add_withdrawal_account_click',
              Trigger: '點擊开户支行',
              Parameters: 'content',
              Value: 'branch',
            })
          }}
          onInput={handleChange}
          onBlur={handleBlur}
        ></Input>
      </FormRow>
      <TextError>{touched.branchName && errors.branchName}</TextError>
      <TextError>{errorMessage}</TextError>
      <FullWidthPrimaryButton type="submit" onClick={handleSubmit} disabled={!isValid || !dirty}>
        确定送出
      </FullWidthPrimaryButton>
    </Wrapper>
  )
}
