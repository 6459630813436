import { ColoredIcon } from 'component/ColoredIcon'
import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: inherit;
  width: 100%;
  margin: 0.56rem 0 0.4rem 0;
  font-size: 0.373rem;
  gap: 0.026rem;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 1.333rem;
  margin: 0 0.373rem 0 0.293rem;
  border-bottom: 1px solid var(--main-color);
  user-select: 'none';
  touch-action: 'none';
`

export const ArrowRight = styled(ColoredIcon).attrs({
  src: `${process.env.PUBLIC_URL}/icon/arrow.svg`,
})`
  width: 0.38462rem;
  height: 0.38462rem;
  transform: rotateY(180deg);
`
