import { useMobileConfigUpdateChecker } from 'hooks/useMobileConfigUpdateChecker'
import { useUpdateChecker } from 'hooks/useUpdateChecker'

const UpdateChecker = () => {
  useUpdateChecker()
  useMobileConfigUpdateChecker()
  return <></>
}

export default UpdateChecker
