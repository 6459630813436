import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: auto 1fr;
  background: var(--component-modal-game-service-background);
  bottom: 0;
  top: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.6rem 0.2rem max(0.6rem, env(safe-area-inset-bottom)) 0.2rem;
  color: var(--component-modal-game-service-color);
  width: 100%;
  font-size: 0.35rem;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-bottom: 0.4rem;
`

export const Avatar = styled.img`
  width: 1.4rem;
  height: 1.4rem;
`

export const Title = styled.div`
  font-size: 0.4rem;
`

export const Subtitle = styled.div`
  line-height: 0.45rem;
`

export const ServiceList = styled.div`
  overflow: auto;
  padding: 0 0.4rem;
`

export const ServiceItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0.3rem 0;
  border-bottom: 1px solid #3e3e3e;
`
