import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'redux/store'
import './index.css'
import { router } from './router'
import reportWebVitals from './reportWebVitals'
import { load } from 'utils/selfPtotect'
import md5 from 'md5'
import { SearchHistoryProvider } from 'hooks/useSearchHistory'
import { DirtyWorkDataProvider } from 'hooks/useDirtyWorkData'
import { RouterProvider } from 'react-router-dom'
import { INIT_APP } from 'redux/constant/app'
import { combinedStorage } from 'utils/combinedStorage'

document.querySelector('html').classList.remove('pre-js')

const url = new URL(window.location.href)
const name = 'cοde'
if (url.searchParams.get(/* ο 是 \u03bf */ name)) {
  window.name = url.searchParams.get(name)
  url.searchParams.delete(name)
  window.location.replace(url.toString())
}

if (
  (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_TEST_PROTECT) &&
  !process.env.REACT_APP_DISABLE_PROTECT &&
  md5(window.name || '') !== '5ec2ee281a1422d6e870b6b5d52ff03f'
) {
  load()
}

const loadSW = async (cb = () => {}) => {
  if (
    process.env.REACT_APP_USE_SW &&
    !process.env.REACT_APP_USE_HASH_ROUTER &&
    'serviceWorker' in navigator &&
    window.location.protocol === 'https:'
  ) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      })
      if (registration.installing) {
        console.log('Service worker installing')
      } else if (registration.waiting) {
        console.log('Service worker installed')
      } else if (registration.active) {
        console.log('Service worker active')
      }

      let pollingId = null

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data === 'activated') {
          clearInterval(pollingId)
          console.log(`The service worker is now activated`)
          // event is a MessageEvent object
          cb()
        }
      })

      navigator.serviceWorker.ready.then((registration) => {
        pollingId = setInterval(() => {
          registration.active.postMessage('poll')
        }, 500)
        registration.active.postMessage('wait-active')
      })
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  } else {
    cb()
  }
}

loadSW(() => {
  store.dispatch({ type: INIT_APP })
})

if (process.env.NODE_ENV !== 'production') {
  import('hide-cra-error-overlay').then(({ initHideOverlay }) => initHideOverlay({ withRestoreButton: false }))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <DirtyWorkDataProvider>
        <SearchHistoryProvider>
          <RouterProvider router={router} />
        </SearchHistoryProvider>
      </DirtyWorkDataProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

const element = document.querySelector('#root')

const ALLOW_GO_BACK = true
const ALLOW_GO_NEXT = false

// https://pqina.nl/blog/blocking-navigation-gestures-on-ios-13-4/
element.addEventListener('touchstart', (e) => {
  // probably other browser
  if (e.pageX == null) {
    return
  }
  let isGoingBack = e.pageX <= 20
  let isGoingNext = e.pageX >= window.innerWidth - 20

  // is not near edge of view, exit
  if (!isGoingBack && !isGoingNext) {
    return
  }

  /**
   * @type {HTMLElement}
   */
  let el = e.target
  while (el) {
    if (el.classList.contains('no-prevent-swipe')) {
      return
    }
    el = el.parentElement
  }

  if (isGoingBack && !ALLOW_GO_BACK) {
    // prevent swipe to navigate back gesture
    e.preventDefault()
  } else if (isGoingNext && !ALLOW_GO_NEXT) {
    // prevent swipe to navigate next gesture
    e.preventDefault()
  }
})

// 先記下來，以免跳轉時被洗掉
export const PRELOAD_ID_KEY = 'p_id'
export const PRELOAD_ID_HASH_KEY = 'p_idh'

{
  const url = new URL(window.location.href)
  const id = url.searchParams.get('id')
  const id_hash = url.searchParams.get('h')

  if (id && id_hash) {
    combinedStorage.setItem(PRELOAD_ID_KEY, id)
    combinedStorage.setItem(PRELOAD_ID_HASH_KEY, id_hash)
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
