import { useMemo, useEffect, useState, useRef, useCallback } from 'react'
import { fetchGameMarquee, postGame } from 'api/index'
import { useAlert } from 'react-alert'

import { PageWrapper, GameFrame } from './Styled'
import Toolbar from './components/Toolbar'
import ConfirmModal from './components/ConfirmModal'
import Loading from 'page/GameLobby/component/Loading'
import { useParamsOfPage } from 'hooks/useNavigateTo'
import { usePageActivated } from 'app-layered-layout/usePageActivated'
import { useToastAlert } from 'hooks/useToastAlert'
import CustomerServiceModal from 'page/GameLobby/component/CustomerServiceModal'
import useScreenOrientation from 'hooks/useScreenOrientation'

export default function Game() {
  const { id: gameId, gameType, tpCode, direction } = useParamsOfPage('game-now')
  const directionType = Number(direction)
  const pageActivated = usePageActivated()

  const [gameLink, setGameLink] = useState({})
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [csOpen, setCsOpen] = useState(false)
  const gameUrl = useMemo(() => {
    if (gameLink && gameLink.urls) return gameLink.urls[0] || ''
    return ''
  }, [gameLink])
  const frameRef = useRef(null)
  const alert = useAlert()

  const [csList, setCsList] = useState([])
  const { rotationDegree } = useScreenOrientation(directionType)
  const { toast } = useToastAlert()
  const showToast = useCallback(
    (message) => {
      toast(message, { rotation: rotationDegree })
    },
    [rotationDegree, toast]
  )

  const handleLoadGameInfo = useCallback(async () => {
    setLoading(true)
    try {
      const data = await postGame({ gameId: gameId, gameType, tpCode })
      setGameLink(data)
    } catch (ex) {
      if (ex?.message) {
        showToast(ex?.message)
      } else {
        alert.show('游戏载入异常', { position: 'middle' })
      }
    }
    setLoading(false)
  }, [alert, gameId, gameType, showToast, tpCode])

  async function loadCustomServiceInfo() {
    const data = await fetchGameMarquee()
    setCsList([...data.customerServiceUrl])
  }

  function handleClose(param) {
    setConfirmModalOpen(true)
  }

  function closeGame() {
    window.close()
  }

  function handleDeposit() {
    /** @type {Window} */
    const parent = window.opener
    parent.postMessage(
      {
        source: 'game-now',
        type: 'deposit',
      },
      '*'
    )
  }

  const handleOpenServiceList = useCallback(() => {
    if (csList.length > 0) {
      setCsOpen(true)
    } else {
      showToast('目前暂无客服人员')
      console.warn('no customer service available')
    }
  }, [csList.length, showToast])

  const handleToServicePage = useCallback(({ url }) => {
    if (url) {
      window.open(url)
    }
  }, [])

  useEffect(() => {
    if (gameId) {
      handleLoadGameInfo()
    }
    loadCustomServiceInfo()
  }, [gameId, handleLoadGameInfo])

  return (
    <PageWrapper>
      {pageActivated && gameUrl && <GameFrame ref={frameRef} src={gameUrl} wmode="Opaque" frameborder="0"></GameFrame>}
      <Toolbar
        direction={directionType}
        onLobbyClick={handleClose}
        onDepositClick={handleDeposit}
        onServiceClick={handleOpenServiceList}
      ></Toolbar>
      <ConfirmModal
        direction={directionType}
        title="退出游戏"
        message="您真的要退出游戏吗？"
        isOpen={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={closeGame}
      ></ConfirmModal>
      <CustomerServiceModal
        list={csList}
        isOpen={csOpen}
        direction={directionType === 1 ? 1 : 2}
        onClose={() => setCsOpen(false)}
        onClick={handleToServicePage}
      />
      {loading ? (
        <Loading active={loading} lockLandScape={directionType === 2}>
          加载中
        </Loading>
      ) : (
        <></>
      )}
    </PageWrapper>
  )
}
