import Splash from 'component/LaunchSplash'
import { ContactButton, MaintenancePageWrapper, PageContent, PageContentLine, PageFooter, PageTitle } from './Styled'
import React, { useCallback, useMemo, useState } from 'react'
import { FullWidthPrimaryButton } from 'commonStyledComponents'
import { useSelector } from 'react-redux'
import { selectFullPageError } from 'redux/selector/app'
import { getMetadataUrl, processMetaRes } from 'utils/id-util/update-util'
import { getVisitorId } from 'redux/saga/user'
import CustomerServiceModal from 'page/GameLobby/component/CustomerServiceModal'

export default function FullPageError() {
  const info = useSelector(selectFullPageError)
  const title = info?.title || '发生错误'
  const lines = useMemo(() => [`${info?.message || '连线失败'}`], [info])

  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION + '.' + process.env.REACT_APP_GIT_SHA
  }, [])

  const handleRetry = useCallback(() => {
    window.location.replace('/')
  }, [])

  // const landingUrl = useMemo(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) return 'https://' + process.env.REACT_APP_LANDING_PAGE_HOST
  // }, [])

  // const handleToLanding = useCallback(() => {
  //   if (process.env.REACT_APP_LANDING_PAGE_HOST) window.open(landingUrl, '_blank')
  // }, [landingUrl])

  const [contactOpen, setContactOpen] = useState(false)
  const [contactList, setContactList] = useState([])

  const handleContact = async () => {
    if (process.env.REACT_APP_BUCKET_SITE_CODE && process.env.REACT_APP_BUCKET_SITE_KEY) {
      try {
        const metaUrl = getMetadataUrl(process.env.REACT_APP_BUCKET_SITE_CODE, process.env.REACT_APP_BUCKET_SITE_KEY)
        const config = JSON.parse(await processMetaRes(await fetch(metaUrl, { cache: 'no-cache' })))
        // const selectedUrl = config.help_desks[Math.floor(Math.random() * config.help_desks.length)]

        // const url = new URL(selectedUrl)
        // url.searchParams.set('id', getVisitorId())
        // window.location.href = url.toString()
        setContactList(config.help_desks.map((url, i) => ({ id: i, url })))
        setContactOpen(true)
      } catch (err) {
        setContactOpen(true)
      }
    }
  }

  return (
    <React.Fragment>
      <Splash></Splash>
      <MaintenancePageWrapper>
        <PageContent>
          <PageTitle>{title}</PageTitle>
          {lines.map((m, i) => (
            <PageContentLine key={i}>{m}</PageContentLine>
          ))}
        </PageContent>

        <FullWidthPrimaryButton onClick={handleRetry}>重试</FullWidthPrimaryButton>
        <ContactButton onClick={handleContact}>联系客服</ContactButton>
        <PageFooter>
          {/* <PageContentLine>{landingUrl && <span onClick={handleToLanding}>前往官网</span>}</PageContentLine> */}
          <PageContentLine>{version}</PageContentLine>
        </PageFooter>
      </MaintenancePageWrapper>
      <CustomerServiceModal
        isOpen={contactOpen}
        list={contactList}
        onClose={() => setContactOpen(false)}
        onClick={(item) => {
          const url = new URL(item.url)
          url.searchParams.set('id', getVisitorId())
          // window.location.href = url.toString()
          window.open(url.toString(), '_blank')
        }}
        overlayStyle={{
          zIndex: 'calc(var(--layer-fullpage-modal) + 2)',
        }}
      />
    </React.Fragment>
  )
}
