import { PRELOAD_ID_HASH_KEY, PRELOAD_ID_KEY } from 'index'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import actions from 'redux/action'
import { combinedStorage } from 'utils/combinedStorage'
import { canInstallNewConfig, generateChecksum, unShuffleHex } from 'utils/id-util/update-util'

const iOSIsInstalled = window.navigator.standalone === true

export const useMobileConfigUpdateChecker = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!process.env.REACT_APP_BUCKET_SITE_CODE || !process.env.REACT_APP_BUCKET_SITE_KEY) {
      return
    }

    // 只有在PWA的環境下才跳出安裝提示
    if (!(iOSIsInstalled || process.env.REACT_APP_TEST_DESKTOP_UPDATE)) {
      return
    }

    if (combinedStorage.getItem(PRELOAD_ID_KEY) && combinedStorage.getItem(PRELOAD_ID_HASH_KEY)) {
      const id = combinedStorage.getItem(PRELOAD_ID_KEY)
      const h = combinedStorage.getItem(PRELOAD_ID_HASH_KEY)
      if (/^[0-9a-f]{32}$/.test(id) && /^[0-9a-f]{64}$/.test(h)) {
        const actualId = unShuffleHex(id)
        if (generateChecksum(actualId) === h) {
          return
        }
      }
    }

    let aborted = false
    const checkUpdate = async () => {
      const res = await canInstallNewConfig(
        process.env.REACT_APP_BUCKET_SITE_CODE,
        process.env.REACT_APP_BUCKET_SITE_KEY
      )
      if (!res || aborted) {
        return
      }
      dispatch(actions.updateShowFullScreenUpdate(true))
    }

    checkUpdate()

    return () => {
      aborted = true
    }
  }, [dispatch])
}
