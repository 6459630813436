import { FullWidthPrimaryButton } from 'commonStyledComponents'
import styled from 'styled-components'

export const MaintenancePageWrapper = styled.div`
  display: flex;
  position: fixed;
  inset: 0 0 0 0;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: calc(var(--layer-fullpage-modal) + 1);
  color: #fff;
  text-align: center;
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PageTitle = styled.div`
  font-size: 0.4rem;
  font-weight: 700;
  padding-bottom: 0.6rem;
`

export const PageContentLine = styled.div`
  font-size: 0.35rem;
  padding-bottom: 0.5rem;
`

export const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.4rem;
`

export const ContactButton = styled(FullWidthPrimaryButton)`
  background: var(--button-secondary-background);
  color: var(--button-secondary-color);
  margin-top: 0.2rem;
`
