import { PrimaryButton } from 'commonStyledComponents'
import { Avatar, Header, ServiceItem, ServiceList, Subtitle, Title, Wrapper } from './styled'
import { animated, easings, useSpring } from '@react-spring/web'
import { ReactHintModal } from 'component/Modal/HintModal'
import { CSSProperties, ReactNode, useMemo } from 'react'
import useScreenOrientation from 'hooks/useScreenOrientation'
export interface CustomerServiceItem {
  id: number
  url: string
}



export default function CustomerServiceModal({
  isOpen,
  onClose,
  list,
  onClick,
  overlayStyle,
  direction = 0
}: {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  list: CustomerServiceItem[]
  overlayStyle?: CSSProperties,
  direction: 0 | 1 | 2
  onClick: (item: CustomerServiceItem) => void
}) {
  const { rotationDegree } = useScreenOrientation(direction === 2 ? 2 : 1)
  const styles = useMemo(()=>{

    const isPortrait = rotationDegree === 0 || rotationDegree === 180;
    if(direction === 1){
      // 直版
      return {
        overlay: {
          zIndex: 101,
          backgroundColor: 'rgba(16, 4, 27, 0.5)',
          width: "100vw",
          height: "100vh",
          ...overlayStyle,
        },
        content: {
          width: isPortrait ? "100vw" : "100vh",
          height: isPortrait ? "100vw" : "100vh",
          inset: isPortrait ? 'auto 0 0 0' : rotationDegree === 270 ? '0 0 0 auto' : '0 auto 0 0',
          transformOrigin: "center",
          transform: `rotate(${isPortrait ? 0 : rotationDegree === 90 ? 90 : -90}deg)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          borderRadius: "10px 10px 0 0",
          transition: "transform 0.3s ease-out",
        },
      };
    }
    else if(direction === 2){
      // 橫版
      return {
        overlay: {
          zIndex: 101,
          backgroundColor: 'rgba(16, 4, 27, 0.5)',
          width: "100vw",
          height: "100vh",
          ...overlayStyle,
        },
        content: {
          width: isPortrait ? "100vh" : "100vw",
          height: isPortrait ? "100vh" : "100vw",
          inset: isPortrait ? '0 0 0 auto' : 'auto 0 0 0',
          transformOrigin: "center",
          transform: `rotate(${isPortrait ? 0 : 90}deg)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          borderRadius: "10px 0 0 10px",
          transition: "transform 0.3s ease-out",
        },
      };
    }
    else{
      // 大廳，使用裝置朝向
      return {
        overlay: {
          zIndex: 101,
          backgroundColor: 'rgba(16, 4, 27, 0.5)',
          width: "100vw",
          height: "100vh",
          ...overlayStyle,
        },
        content: {
          width: isPortrait ? "100vw" : "100vh",
          height: isPortrait ? "100vw" : "100vh",
          inset: isPortrait ? 'auto 0 0 0' : '0 0 0 auto',
          transformOrigin: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          borderRadius: isPortrait ? "10px 10px 0 0" : "10px 0 0 10px",
          transition: "transform 0.3s ease-out",
        },
      };
    }
  }, [direction, overlayStyle, rotationDegree])
  const springStyles = useSpring({
    config: { duration: 150, easing: easings.easeInCubic },
    width: '100%',
    height: isOpen ? '100%' : '0',
    opacity: isOpen ? 1 : 0.2,
  })

  return (
    <>
      {isOpen ? (
        <ReactHintModal
          contentLabel="Hint Modal"
          isOpen={isOpen}
          style={styles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={onClose}
        >
          <animated.div style={springStyles}>
            <Wrapper className={"rotateClassName"}>
              <Header>
                <Avatar src={`${process.env.PUBLIC_URL}/game/cs-avatar.png`} />
                <Title>7x24在线客服</Title>
                <Subtitle>专业客服在线对话，为您解决遇到的问题</Subtitle>
              </Header>
              <ServiceList>
                {list ? (
                  list.map((m, i) => (
                    <ServiceItem key={i}>
                      <div>在线客服 {i + 1}</div>
                      <PrimaryButton onClick={() => onClick(m)}>立即联系</PrimaryButton>
                    </ServiceItem>
                  ))
                ) : (
                  <div>暂无在线客服</div>
                )}
              </ServiceList>
            </Wrapper>
          </animated.div>
        </ReactHintModal>
      ) : (
        <></>
      )}
    </>
  )
}
