import { useState, useRef } from 'react'

/**
 * 步驟
 * 1. 長按5秒
 * 2. 放開2秒
 * 3. 連續點擊4下
 * @param onSuccess 成功後的回呼方法
 * @returns
 */
const useDevButtonClick = (onSuccess: () => void) => {
  const [step, setStep] = useState(0)
  // step 1 timer
  const holdTimer = useRef<NodeJS.Timeout | null>(null)
  // step 2 timer
  const waitTimer = useRef<NodeJS.Timeout | null>(null)
  // reset timer
  const sequenceTimeout = useRef<NodeJS.Timeout | null>(null)
  // step 3 counter
  const clickCount = useRef(0)

  // step1: 開始計時長按秒數
  const handleButtonDown = () => {
    if (holdTimer.current) clearTimeout(holdTimer.current)
    if (step === 1 || step === 2) {
      // step2 太早按
      reset()
      return
    } else if (step > 2) {
      return
    }
    // 這個部分只有在第一步執行
    // console.log('step1: start')
    holdTimer.current = setTimeout(() => {
      // 長按5秒完成
      setStep(1)
      // console.log('step1: ok')
    }, 5000)
  }

  // step1: 檢查長按秒數
  const handleButtonUp = () => {
    if (holdTimer.current) {
      clearTimeout(holdTimer.current)
      holdTimer.current = null
    }

    if (step === 1) {
      // step2 完成，等候2秒
      setStep(2)
      startClickCheckPeriod()

      waitTimer.current = setTimeout(() => {
        // 等候2秒完成，開始點擊
        setStep(3)
        // console.log('step2: ok')
      }, 2000)
    }
  }

  const startClickCheckPeriod = () => {
    // 後續步驟需要在10秒內完成
    sequenceTimeout.current = setTimeout(() => {
      if (clickCount.current === 4) {
        // 成功
        onSuccess()
        reset()
        // console.log('success')
      } else {
        reset() // 逾時則重置
      }
    }, 10000)
  }

  // step3: 記錄點擊次數
  const handleButtonClick = () => {
    if (step !== 3) return // 只有在步驟3生效

    // 點擊次數+1
    clickCount.current += 1
    // console.log('step3: ' + clickCount.current)

    if (clickCount.current > 4) {
      // 超過4下重置
      reset()
    }
  }

  const reset = () => {
    setStep(0)
    clickCount.current = 0
    if (holdTimer.current) clearTimeout(holdTimer.current)
    if (waitTimer.current) clearTimeout(waitTimer.current)
    if (sequenceTimeout.current) clearTimeout(sequenceTimeout.current)
  }

  return { handleButtonDown, handleButtonUp, handleButtonClick }
}

export default useDevButtonClick
